import { GithubLang } from "@altenull/github-lang-to-icon";
import { ReactNode } from "react";

export interface RepoUser {
    avatar_url: string
    login: string
}

export interface User {
    avatar_url: string
    login: string
    followers: number
    following: number
    public_repos: number
}

export const defaultUser = {
    avatar_url: "",
    login: "",
    followers: 0,
    following: 0,
    public_repos: 0
}

export interface Repo {
    id: number | string
    name: string
    full_name: string
    description: string
    forks_count: number
    homepage: string
    language: string
    stargazers_count: number
    owner: RepoUser
    updated_at: string
    created_at: string
    default_branch: string
    git_url: string
    tags: string[]
}

export const defaultRepo = {
    default_branch: "",
    description: "",
    forks_count: 0,
    full_name: "",
    git_url: "",
    homepage: "",
    id: 0,
    language: "",
    name: "",
    owner: undefined,
    stargazers_count: 0,
    updated_at: "",
    created_at: "",
    tags: []
}

export interface Lang {
    text: ReactNode, icon: ReactNode, key: string
}


export interface TrendingLangNavItem {
    text: string | ReactNode;
    itemKey: string | GithubLang;
    onClick?: any;
    icon?: ReactNode;
}

export const defaultTrendingLanguages = [
    { "text": "All languages", "itemKey": "" },
    { "text": "Go", "itemKey": "go" },
    { "text": "Java", "itemKey": "java" },
    { "text": "Javascript", "itemKey": "javascript" },
    { "text": "Typescript", "itemKey": "typescript" },
    { "text": "PHP", "itemKey": "php" },
    { "text": "Python", "itemKey": "python" },
    { "text": "C", "itemKey": "c" },
    { "text": "C++", "itemKey": "c%2B%2B" },
    { "text": "C#", "itemKey": "c%23" },
    { "text": "V", "itemKey": "v" },
    { "text": "Rust", "itemKey": "rust" },
    { "text": "Css", "itemKey": "css" },
    { "text": "HTML", "itemKey": "html" },
    { "text": "Ruby", "itemKey": "ruby" },
    { "text": "R", "itemKey": "r" },
    { "text": "Shell", "itemKey": "shell" },
    { "text": "Swift", "itemKey": "swift" },
    { "text": "Dart", "itemKey": "dart" },
    { "text": "Flutter", "itemKey": "flutter" },
]

