import { Image, Toast, Spin } from "@douyinfe/semi-ui"
import { useEffect, useState } from "react";

function Login(props) {
    const [loading, setLoading] = useState(true)
    const params = new URLSearchParams(window.location.search);
    let code = params.get('code');

    useEffect(() => {
        if (!code) {
            return setLoading(false);
        }
        fetch('https://api.xiusin.cn/github/oauth2/callback?code=' + code, { method: "GET" }).then(response => response.json()).then(data => {
            if (data.error) {
                return Toast.error(data.error_description)
            }
            code = '';
            props.setToken(data.access_token)
        }).catch(e => {
            Toast.error(e.message)
        })
    }, [])


    const redirect_uri = 'https://star.xiusin.cn/'
    const url = `https://github.com/login/oauth/authorize?client_id=e2ccd3b8e35a515b3bda&scope=user:profile,user:fllowers,user:stars,user:starred&redirect_uri=${redirect_uri}&state=${parseInt((Math.random() * 10e5) + '')}`;


    return <div className='login'>
        <Spin spinning={loading}>
            <img alt='' className='img1' src='/background.png' />
            <div className='login-box' style={{ position: 'relative', top: '-300px' }}>
                <Image src={"/ohmystar.webp"} height={100} preview={false} />
                <div></div>
                <a href={url} className="github-login-btn">
                    <img src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png" alt="GitHub Logo" />
                    Login with GitHub
                </a>
            </div>
            <img alt='' className='img2' src='/background.png' />
        </Spin>
    </div>
}

export default Login
