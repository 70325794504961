import './App.css';
import "github-markdown-css"
import { useCallback, useEffect, useState, useMemo } from 'react';
import {
    Layout,
    Image,
    Col,
    Row,
    Nav,
    ButtonGroup,
    Button,
    List,
    Spin,
    Empty,
    Input,
    Tag,
    TagInput,
    Typography,
    Dropdown,
    Notification,
    Form,
    CheckboxGroup,
    Select,
    Toast,
    Tooltip,
} from '@douyinfe/semi-ui';
import {
    IconSearch,
    IconMonitorStroked,
    IconLineChartStroked,
    IconSearchStroked,
    IconUser,
    IconHomeStroked,
    IconStar,
    IconSetting,
    IconStarStroked,
    IconAscend,
    IconClock,
    IconClose,
    IconSourceControl,
    IconCalendarClock,
    IconSync,
    IconPriceTag,
    IconCode,
    IconGithubLogo,
} from "@douyinfe/semi-icons";
import { Octokit } from "@octokit/core";
import { decode } from "js-base64"

import { IllustrationIdle, IllustrationIdleDark } from '@douyinfe/semi-illustrations';

import { GithubLang, GithubLangToIcon } from '@altenull/github-lang-to-icon';
import { Repo, User, Lang, defaultRepo, defaultUser, defaultTrendingLanguages, TrendingLangNavItem } from "./intf";
import { NavItemProps } from '@douyinfe/semi-ui/lib/es/navigation/Item';
import Login from './Login';
import { SimpleImg } from 'react-simple-img';
import { Readme } from './Readme';

let repos: Repo[] = JSON.parse(localStorage.getItem("repos")) || [];
let token = localStorage.getItem("git_token") || "";
const database = indexedDB.open("ohmystar3");

database.onupgradeneeded = function (event) {
    const db = database.result
    let storeName = 'readmes'
    if (!db.objectStoreNames.contains(storeName)) { // 判断表是否存在
        db.createObjectStore(storeName, { keyPath: 'repo_id' })
    }
}

const addData = function (storeName, data) {
    try {
        database.result.transaction([storeName], "readwrite").objectStore(storeName).add(data);
    } catch (e) {
    }
}
const getData = function (storeName, id) {
    return new Promise((resolve, reject) => {
        try {
            let request = database.result.transaction([storeName]).objectStore(storeName).get(id)
            request.onsuccess = function () {
                resolve(request.result)
            }
            request.onerror = function (event) {
                reject(null)
            }
        } catch (e) {
            reject(null)
        }
    })
}


let _tagList = new Map();
let octokit = new Octokit({ auth: token });
let readmeLinkDenied = false;

let defaultSearchInfo = {
    searchName: true,
    searchOwner: true,
    searchDescription: true,
    language: '',
    tags: '',
    keyword: '',
    eventTime: 0,
};

// @ts-ignore
const isTauri = typeof window.__TAURI__ !== 'undefined';

function App() {
    const { Text } = Typography;
    const [dataSource, setData] = useState<Repo[]>([]);
    const [readme, setReadme] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [readmeLoading, setReadmeLoading] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<string>("updated_at");
    const [navKeys, setNavKeys] = useState<string[]>(['all']);
    const [navTags, setNavTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [menuKey, setMenuKey] = useState("manage");
    const [trendLanguages, setTrendLanguages] = useState<NavItemProps[]>([])
    const [repo, setRepo] = useState<Repo>(defaultRepo)
    const [user, setUser] = useState<User>(JSON.parse(localStorage.getItem("user")) || defaultUser)
    const [star, setStar] = useState<number>(0)
    const { Content, Sider } = Layout;
    const [navLanguage, setNavLanguage] = useState<Lang[]>([]);
    const [gitToken, setGitToken] = useState<string>(token);
    const [lang, setLang] = useState<string>('');
    const [searchInfo, setSearchInfo] = useState({ ...defaultSearchInfo });


    const checkErr = function (e: Error) {
        if (e.message === "Bad credentials") logout()
        Notification.error({ content: e.message, duration: 3 });
    }

    const getUser = useCallback(async () => {
        if (!user.login) {
            const ud = (await octokit.request('GET /user', {})).data
            setUser(ud);
            localStorage.setItem("user", JSON.stringify(ud));
        }
    }, [user.login])

    const setToken = (_token: string) => {
        token = _token
        octokit = new Octokit({ auth: token });
        localStorage.setItem("git_token", token);
        setGitToken(token)
    }

    const logout = () => {
        setToken('')
        localStorage.removeItem("user");
        localStorage.removeItem("repos");
    };

    const reloadData = () => {
        database.result.transaction(["readmes"], "readwrite").objectStore("readmes").clear();
        localStorage.removeItem("user");
        localStorage.removeItem("repos");
        window.location.reload()
    };

    const updateNavTags = (withRepos: boolean = true) => {
        let tagList = []
        _tagList.forEach((v, tag) => tagList.push({
            "itemKey": "tag-" + tag,
            "text": <div style={{ textAlign: "left" }}>{tag} <Tag
                color={'blue'}
                size="small"
                shape='circle'
                style={{ float: 'right' }}
            >{v}</Tag></div>,
            "name": tag,
            "count": v,
        }))
        setNavTags(tagList.sort((a, b) => b.name < a.name ? 1 : -1).sort((a, b) => b.count - a.count));

        if (withRepos) {
            setTimeout(() => localStorage.setItem("repos", JSON.stringify(repos)), 0);
        }
    }

    const getTrending = useCallback(async (language) => {
        setLoading(true);
        const list = await fetch("https://gtrend.yapie.me/repositories?since=daily&language=" + (language || "").replace("trend-", "")).then((data) => data.json()).catch(checkErr)
        const repos: Repo[] = [];
        list.forEach((repo: any) => {
            repos.push({
                id: repo.author + "/" + repo.name,
                name: repo.name,
                full_name: repo.author + "/" + repo.name,
                description: repo.description,
                forks_count: repo.forks,
                homepage: repo.url,
                language: repo.language,
                stargazers_count: repo.stars,
                owner: {
                    login: repo.author,
                    avatar_url: repo.avatar
                },
                updated_at: '-',
                created_at: '-',
                default_branch: 'master',
                git_url: repo.language,
                tags: []
            })
        })
        setData(repos)
        setLoading(false);
    }, []);

    const fetchData = useCallback(async (page: number = 1) => {
        console.log('fetchData', menuKey, navKeys, page)
        let list = [];
        if (repos.length === 0) {
            list = (await octokit.request('GET /user/starred{?sort,direction,per_page,page}', { "per_page": 100, "page": page })).data
            if (list.length === 100) {
                const nextPageData = await fetchData(page + 1)
                list.push(...nextPageData)
            }
            setTimeout(() => {
                localStorage.setItem("repos", JSON.stringify(list));
                repos = [...list]
            }, 0)
        } else if (menuKey !== 'trending') {
            list = repos;
        }
        if (page === 1) {
            let langNavList = []
            const collections = new Map();
            _tagList = new Map();
            list.forEach((item: Repo) => {
                if (item.language) {
                    if (!collections.has(item.language)) {
                        collections.set(item.language, 0);
                    }
                    collections.set(item.language, collections.get(item.language) + 1);
                }
                const tl = item.tags || []
                for (const itemKey in tl) {
                    if (!_tagList.has(tl[itemKey])) {
                        _tagList.set(tl[itemKey], 0)
                    }
                    _tagList.set(tl[itemKey], _tagList.get(tl[itemKey]) + 1);
                }
            })

            const trends = [];

            defaultTrendingLanguages.forEach((v: TrendingLangNavItem, idx) => {
                v = {
                    onClick: ({ itemKey }) => getTrending(itemKey),
                    "itemKey": "trend-" + v.itemKey,
                    "text": <div style={{ textAlign: "left" }}>{v.text}</div>,
                    "icon": v.itemKey === '' ? <IconCode size='large' /> : <GithubLangToIcon lang={v.itemKey as GithubLang} size={20} />
                }
                trends[idx] = v
            })

            setTrendLanguages(trends)

            collections.forEach((v, lang) => langNavList.push({
                onClick: function ({ itemKey }) {
                    setLang(itemKey)
                },
                "itemKey": lang,
                "key": lang,
                "text": <div style={{ textAlign: "left" }}>{lang} <Tag
                    color={'blue'}
                    size="small"
                    shape='circle'
                    style={{ float: 'right' }}
                >{v}</Tag></div>,
                "count": v,
                "icon": <GithubLangToIcon lang={lang} size={20} />
            }))
            setNavLanguage(langNavList.sort((a, b) => b.count - a.count));
            updateNavTags(false)
            setStar(repos?.length || 0);
            if (searchInfo.keyword) {
                if (searchInfo.searchDescription || searchInfo.searchName || searchInfo.searchOwner) {
                    list = list.filter((item: Repo) => {
                        try {
                            return (searchInfo.searchOwner && item.owner?.login?.toLowerCase().includes(searchInfo.keyword.toLowerCase())) ||
                                (searchInfo.searchName && item.name?.toLowerCase().includes(searchInfo.keyword.toLowerCase())) ||
                                (searchInfo.searchDescription && item.description?.toLowerCase().includes(searchInfo.keyword.toLowerCase()))
                        } catch (e) {
                            return false;
                        }
                    })
                }
            }

            if (lang !== '') {
                list = list.filter((item: Repo) => item.language === lang)
            }

            if (searchInfo.language && searchInfo.keyword) {
                list = list.filter((item: Repo) => item.language === searchInfo.language)
            }

            if (navKeys[0]?.startsWith("tag-", 0)) {
                list = list.filter((item: Repo) => item.tags ? item.tags.includes(navKeys[0].substring(4)) : false)
            }

            if (searchInfo.keyword && searchInfo.tags?.startsWith("tag-", 0)) {
                list = list.filter((item: Repo) => item.tags ? item.tags.includes(searchInfo.tags.substring(4)) : false)
            }

            if (navKeys[0] === 'untag') {
                list = list.filter((item: Repo) => !item.tags)
            }

            list = list.sort((a, b) => sortBy === 'stargazers_count' ? b[sortBy] - a[sortBy] : b[sortBy].localeCompare(a[sortBy]))

            setData(list);
            setLoading(false);
        }
        return list
    }, [getTrending, lang, navKeys, sortBy, searchInfo, menuKey])

    const getContent = () => {
        if (repo.id === 0) {
            return <div>
                <Empty
                    style={{ marginTop: "300px" }}
                    image={<IllustrationIdle style={{ width: 150, height: 150 }} />}
                    darkModeImage={<IllustrationIdleDark style={{ width: 150, height: 150 }} />}
                />
            </div>
        } else {
            return <div>
                <h3><Typography.Paragraph copyable={{ content: repo.git_url }}>
                    <IconHomeStroked size={'large'} />  {repo.full_name}</Typography.Paragraph>
                </h3>
                <div style={{ padding: "3px 0px 8px 0px" }}>
                    <TagInput
                        draggable
                        addOnBlur={true}
                        maxTagCount={5}
                        allowDuplicates={false}
                        value={tags}
                        maxLength={16}
                        onInputExceed={v => {
                            Toast.warning('超过标签允许最大长度');
                        }}
                        prefix={<IconPriceTag />}
                        onChange={v => updateTags(v)}
                    />
                </div>
                <div style={{ margin: '3px 0px 5px 0px' }}><Text>{repo.description}</Text></div>
                <div style={{ padding: '5px 0', fontSize: '10px' }}><Text disabled>
                    Created: {repo.created_at}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Updated: {repo.updated_at}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <IconStar /> {repo.stargazers_count}
                </Text>
                </div>

                <Row style={{ padding: '0 3px 5px 3px', backgroundColor: '#e8e8e8', display: 'none' }}>
                    <Col span={4}>
                        <div className="col-content"><Input prefix={<IconCalendarClock />} disabled
                            defaultValue={repo.updated_at}></Input></div>
                    </Col>
                    <Col span={12}>
                        <div className="col-content"></div>
                    </Col>
                    <Col span={8}>
                        <div className="col-content"><Input prefix={<IconSourceControl />}
                            defaultValue={repo.git_url}></Input>
                        </div>
                    </Col>
                </Row>
                <div
                    style={{
                        minHeight: '500px',
                    }}
                >
                    <Readme content={readme} />
                </div>
            </div>
        }
    }

    const getRepoReadme = async (item: Repo) => {
        if (item.id === repo.id || readmeLoading) return;
        setReadme('');

        item.tags = item.tags || []
        setTags(item.tags)
        item.created_at = item.created_at.substring(0, 10)
        item.updated_at = item.updated_at.substring(0, 10)
        setRepo(item);
        let result = await getData("readmes", item.id)

        const element = document.getElementById('scrollTop');
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });

        setTimeout(() => {
            if (readmeLinkDenied) return;
            const noLinkArea = document.getElementById('readme-box');
            noLinkArea.addEventListener('click', (event) => {

                // @ts-ignore
                if (event.target.tagName === 'A') {
                    if (isTauri) {
                        // TODO  open a new window

                        // @ts-ignore
                        navigator.clipboard.writeText(event.target.href).then(() => {
                            Toast.success({ content: "链接已经复制", duration: 3 });
                        }).catch(checkErr).finally()
                        event.preventDefault();
                    } else {
                        // @ts-ignore
                        event.target.target = '_blank';
                    }
                }
            });
            readmeLinkDenied = true;
        }, 0);

        if (result) {
            // @ts-ignore
            setReadme(result.readme);
        } else {
            setReadmeLoading(true);
            const fetchData = async () => {
                const list = await octokit.request('GET /repos/{owner}/{repo}/readme{?ref}', {
                    "owner": item.owner?.login,
                    "repo": item.name
                })
                const url = `https://raw.githubusercontent.com/${item.owner?.login}/${item.name}/${item.default_branch}/`
                let content = decode(list.data.content)
                content = content.replace(/\]\(\.?\//g, "](")
                    .replace(/\]\(https:\/\/github.com\/(.+?)\.(png|jpg|jpeg|gif|bmp)/g, "](https://raw.githubusercontent.com/$1.$2")
                    .replace(/\/blob\//g, '/')
                    .replace(/src=(["'])\.?\//g, "src=$1" + url)
                    .replace(/src=(["'])(?!http)/g, "src=$1" + url)
                    .replace(/\]\((?!http)(.+?)\)/g, "](" + url + "$1)")

                setTimeout(() => addData("readmes", { repo_id: item.id, readme: content }), 0)
                setReadme(content);
                setReadmeLoading(false);
            }

            setTimeout(() => {
                fetchData().catch((e) => {
                    Notification.error({
                        content: e.message,
                        duration: 3,
                        theme: 'light',
                    })
                    setReadmeLoading(false);
                }).finally()
            }, 0)

        }
    }

    useEffect(() => {
        try {
            document.querySelector(".atom-spinner-loading").remove();
        } catch (e) { }
    }, [])

    useEffect(() => {
        if (gitToken.length === 0) return;
        setLoading(true)
        getUser().catch(checkErr).finally()
        fetchData().catch(checkErr).finally()
    }, [gitToken, getUser, fetchData]);

    const updateTags = (tags: string[]) => {
        repo.tags.forEach(tag => {
            const num = (_tagList.get(tag) || 0) - 1
            num <= 0 ? _tagList.delete(tag) : _tagList.set(tag, num)
        })
        tags.forEach(tag => _tagList.set(tag, (_tagList.get(tag) || 0) + 1))
        setTags(repo.tags = tags)
        updateNavTags()
    }

    const manageMenus = useMemo(() => [
        { itemKey: 'all', text: 'All Repo', icon: <IconUser /> },
        { itemKey: 'tag', text: 'Tag', icon: <IconStar />, items: navTags },
        { text: 'Language', icon: <IconSetting />, itemKey: 'lang', items: navLanguage },
    ], [navTags, navLanguage]);

    if (gitToken.length === 0) {
        return <Login setToken={setToken} />
    }

    return (
        <Layout>
            <Sider className='sider'>
                <a href={'https://github.com/' + user.login} target='_blank' rel="noreferrer" title='Github Profile'>
                    <Image preview={false} width={60} height={60}
                        src={user.avatar_url.replace("https://", "https://images.weserv.nl/?url=")}
                        style={{ marginBottom: "20px", marginTop: "30px" }} alt={''} />
                </a>

                <Row>
                    <Col span={8}>
                        <div className="col-content">
                            <div>{star}</div>
                            <div>Stared</div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="col-content">
                            <div>{user.followers}</div>
                            <div>Followers</div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="col-content">
                            <div>{user.following}</div>
                            <div>Following</div>
                        </div>
                    </Col>
                </Row>

                <Row style={{ margin: "10px" }}>
                    <Col span={8}>
                        <div
                            className={menuKey === 'manage' ? "col-content active" : 'col-content'}
                            onClick={() => {
                                if (menuKey !== 'manage') {
                                    setSearchInfo({ ...defaultSearchInfo })
                                    setMenuKey('manage')
                                    setNavKeys(['all'])
                                }
                            }
                            }
                        >
                            <div className="icon"><IconMonitorStroked /></div>
                            <div>Manage</div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div
                            className={menuKey === 'trending' ? "col-content active" : 'col-content'}
                            onClick={() => {
                                setSearchInfo({ ...defaultSearchInfo })
                                if (menuKey !== 'trending') {
                                    setNavKeys(['trend-'])
                                    setMenuKey('trending')
                                    getTrending('trend-')
                                }
                            }}
                        >
                            <div className="icon"><IconLineChartStroked /></div>
                            <div>Trending</div>
                        </div>
                    </Col>

                    <Col span={8}>
                        <div
                            className={menuKey === 'search' ? "col-content active" : 'col-content'}
                            onClick={() => { menuKey !== 'search' && setMenuKey('search') }}
                        >
                            <div className="icon"><IconSearchStroked /></div>
                            <div>Search</div>
                        </div>
                    </Col>
                </Row>

                {
                    menuKey === 'manage' ? <Nav
                        selectedKeys={navKeys}
                        className="nav"
                        onSelect={(data: any) => {
                            if (data.itemKey === 'all' || data.itemKey === 'untag' || data.itemKey.startsWith('tag-')) {
                                setLang('')
                            }
                            setNavKeys([data.itemKey])
                        }}
                        style={{ fontWeight: "normal" }}
                        items={manageMenus}
                    /> :
                        <>
                            {
                                menuKey === 'search' ? <>
                                    <Form onSubmit={(values) => console.log(values)} style={{ width: 210, marginLeft: 5 }}>
                                        <Form.Input
                                            field='name' showClear prefix={<IconSearch />}
                                            onChange={(value) => {
                                                const current = performance.now();
                                                if (current - searchInfo.eventTime > 100) { // 50ms防抖
                                                    setSearchInfo({
                                                        ...searchInfo,
                                                        keyword: value as string,
                                                        eventTime: current
                                                    })
                                                }
                                            }}
                                            trigger='blur' noLabel={true}
                                            placeholder='关键词' />
                                        <br />
                                        <CheckboxGroup style={{ 'marginLeft': 15 }} defaultValue={['name', 'owner', 'description']} options={[
                                            { label: '名称', value: 'name' },
                                            { label: '作者', value: 'owner' },
                                            { label: '描述', value: 'description' }
                                        ]}
                                            onChange={(values) => {
                                                setSearchInfo({
                                                    ...searchInfo,
                                                    searchName: values.includes('name'),
                                                    searchOwner: values.includes('owner'),
                                                    searchDescription: values.includes('description')
                                                })
                                            }}

                                            direction='horizontal' />
                                        <br />
                                        <Select
                                            defaultValue=""
                                            onChange={(value) => {
                                                setSearchInfo({
                                                    ...searchInfo,
                                                    language: value as string
                                                })
                                            }}
                                            showClear
                                            style={{ width: '100%' }}
                                            placeholder='语言'>
                                            {
                                                navLanguage.map((item: Lang, index) => <Select.Option key={index} value={item.key}> <div style={{ verticalAlign: 'middle', float: 'left', marginRight: '3px' }}>{item.icon}</div>  {item.key}</Select.Option>)
                                            }
                                        </Select>
                                        <br />
                                        <br />
                                        <Select
                                            onChange={(values) => {
                                                setSearchInfo({
                                                    ...searchInfo,
                                                    tags: values as string
                                                })
                                            }}
                                            defaultValue=""
                                            showClear
                                            style={{ width: '100%' }}
                                            placeholder='标签'>
                                            {
                                                navTags.map((item) => <Select.Option value={item.itemKey}> {item.name}</Select.Option>)
                                            }
                                        </Select>

                                    </Form>
                                </> : <Nav
                                    selectedKeys={navKeys}
                                    className="nav"
                                    onSelect={(data: any) => {
                                        if (data.itemKey === 'all' || data.itemKey === 'untag' || data.itemKey.startsWith('tag-')) {
                                            setLang('')
                                        }
                                        setNavKeys([data.itemKey])
                                    }}
                                    style={{ fontWeight: "normal" }}
                                    items={trendLanguages}
                                />
                            }

                        </>
                }
                <Row style={{ position: 'absolute', bottom: 0, textAlign: 'right', width: '100%' }}>
                    <Col span={20}></Col>
                    <Col span={4}>
                        <Dropdown
                            trigger={'click'}
                            render={
                                <Dropdown.Menu>
                                    <Dropdown.Item icon={<IconSync />} onClick={() => { reloadData() }}>同步</Dropdown.Item>
                                    <Dropdown.Item icon={<IconGithubLogo />} type="warning" onClick={() => { window.open('https://github.com/xiusin/ohmystar3') }}>反馈</Dropdown.Item>
                                    <Dropdown.Item icon={<IconClose />} onClick={() => { logout() }}>退出</Dropdown.Item>
                                </Dropdown.Menu>
                            }
                        >
                            <Button theme='borderless' size='small' style={{ backgroundColor: 'unset' }}><IconSetting /></Button>
                        </Dropdown>
                    </Col>
                </Row>
            </Sider>
            <Sider className="repo-list" style={{ width: '300px', minWidth: '300px', maxWidth: '300px' }}>
                <ButtonGroup size={"default"} style={{ width: "250px", marginLeft: "25px" }}>
                    <Tooltip content={'按更新时间'}>
                        <Button type={sortBy === 'updated_at' ? 'secondary' : 'tertiary'}
                            onClick={() => sortBy !== 'updated_at' && setSortBy("updated_at")}><IconClock size='large' /></Button>
                    </Tooltip>
                    <Tooltip content={'按热度'}>
                        <Button type={sortBy === 'stargazers_count' ? 'secondary' : 'tertiary'}
                            onClick={() => sortBy !== 'stargazers_count' && setSortBy("stargazers_count")}><IconStarStroked /></Button>
                    </Tooltip>
                    <Tooltip content={'按名称'}>
                        <Button type={sortBy === 'name' ? 'secondary' : 'tertiary'}
                            onClick={() => sortBy !== 'name' && setSortBy("name")}><IconAscend /></Button>
                    </Tooltip>
                </ButtonGroup>

                <div className="list-box">
                    <List
                        loading={loading}
                        dataSource={dataSource}
                        renderItem={item => (
                            <List.Item onClick={async () => await getRepoReadme(item)}
                                key={item.id}
                                className={repo.id === item.id ? 'active' : ''}
                            >
                                <div className='repo-name'>
                                    <span style={{ float: "left", marginRight: "10px" }}>
                                        <SimpleImg
                                            height={20}
                                            placeholder={"#ccc"}
                                            imgStyle={{ float: "left", borderRadius: '50%' }}
                                            src={item.owner?.avatar_url?.replace("https://", "https://images.weserv.nl/?url=")} />
                                    </span>

                                    {item.full_name}
                                </div>
                                <p className='repo-description' title={item.description}>
                                    {item.description}
                                </p>
                                <div className="list-item-footer">
                                    <span style={{ float: "left" }}>
                                        {item.language ? <Tag color={'teal'} size={'small'}>{item.language}</Tag> : ''}
                                    </span>
                                    <span style={{
                                        float: "right",
                                        textAlign: "right"
                                    }}><IconStarStroked /> {item.stargazers_count}</span>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            </Sider>
            <Layout className={"repo-readme"}>
                <Spin spinning={readmeLoading} tip='正在读取...'>
                    <div id='scrollTop'></div>
                    <Content className='markdown-body'>
                        <div id='readme-box'>
                            {getContent()}
                        </div>
                    </Content>
                </Spin>
            </Layout>
        </Layout>
    );
}

export default App;
