import { Banner } from '@douyinfe/semi-ui';
import { Component } from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import rehypeRaw from 'rehype-raw'


export class Readme extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }


    componentDidUpdate(prevProps) {
        if (prevProps.content !== this.props.content) {
            this.setState({ hasError: false, error: null });
        }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error })
    }

    render() {
        if (this.state.hasError) {
            return <Banner fullMode={false} type="danger" bordered icon={null} closeIcon={null}
                title={<div style={{ fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}>文档内容无法正常解析错误</div>}
                description={this.state.error?.message || '无法解析ReadMe信息'}
            />;
        }

        return <ReactMarkdown rehypePlugins={[rehypeRaw, remarkGfm]}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '')

                    // 删除样式填充
                    const pre = prism[`pre[class*="language-"]`]
                    delete pre.margin
                    prism[`pre[class*="language-"]`] = pre

                    return !inline && match ? (
                        <SyntaxHighlighter
                            children={String(children).replace(/\n$/, '')}
                            style={prism}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                        />
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    )
                }
            }}
        >
            {this.props.content}
        </ReactMarkdown>
    }
}
